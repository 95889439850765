<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      {{ data.name }}
    </h4>
    <b-card no-body>
      <b-card-header>Перевозчики</b-card-header>
      <b-card-body>
        <v-client-table
          id="dataTable"
          ref="table"
          :data="contractors.items"
          :columns="contractors.columns"
          :options="contractors.options"
          :theme="theme"
        >
          <span
            slot="phone"
            slot-scope="props"
          >
            <a
              :href="`tel:${props.row.phone}`"
              class="icon-email"
            >{{ props.row.phone }}</a>
          </span>
          <span
            slot="driversCount"
            slot-scope="props"
          >
            <span v-if="!props.row.driversCount">0</span>
            <span v-else>{{ props.row.driversCount }}</span>
          </span>
          <span
            slot="trucksCount"
            slot-scope="props"
          >
            <span v-if="!props.row.trucksCount">0</span>
            <span v-else>{{ props.row.trucksCount }}</span>
          </span>
          <span
            slot="trailersCount"
            slot-scope="props"
          >
            <span v-if="!props.row.trailersCount">0</span>
            <span v-else>{{ props.row.trailersCount }}</span>
          </span>
          <span
            slot="notificationEmail"
            slot-scope="props"
          >
            <a
              v-if="props.row.notificationEmail"
              :href="`mailto:${props.row.notificationEmail}`"
              class="icon-email"
            >{{ props.row.notificationEmail }}</a>
            <a
              v-else
              :href="`mailto:${props.row.email}`"
              class="icon-email"
            >{{ props.row.email }}</a>
          </span>
        </v-client-table>
      </b-card-body>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <a
          v-if="$store.getters.isAdminOrManager"
          :href="`/contractor-lists-list/contractor-list/id${contractorListId}`"
          class="btn btn-primary mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
        >Редактировать</a>
        <b-button
          v-if="$store.getters.isAdmin"
          type="button"
          variant="danger"
          class="mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
          @click="confirmDeleteContractorsList()"
        >
          Удалить список
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {Event, ClientTable} from 'vue-tables-2';
import notifications from '../../../components/mixins/notifications';
import {customersContractorsListRead, customersContractorsListDelete} from '../../../services/api';

Vue.use(ClientTable);

export default {
  name: 'ContractorListsList',
  components: {
    ClientTable,
    Event,
  },
  mixins: [notifications],
  props: ['contractorListId'],
  data: function() {
    return {
      data: {
        name: null,
      },
      contractors: {
        columns: ['name', 'phone', 'driversCount', 'trucksCount', 'trailersCount', 'notificationEmail', 'INN'],
        options: {
          headings: {
            'name': 'Название',
            'phone': 'Телефон',
            'driversCount': 'Кол-во водителей',
            'trucksCount': 'Кол-во грузовиков',
            'trailersCount': 'Кол-во прицепов',
            'notificationEmail': 'Email',
            'INN': 'ИНН',
          },
          sortable: ['name'],
          filterable: ['name'],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          orderBy: {
            column: 'name',
          },
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Нет совпадающих записей',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        fullDistance: '',
        theme: 'bootstrap4',
      },
      loading: false,
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  watch: {
    '$route'() {
      this.customersContractorsListRead();
    },
  },
  mounted() {
    this.customersContractorsListRead();
  },
  methods: {
    async customersContractorsListRead() {
      this.loading = true;
      const response = await customersContractorsListRead(this.$store.state.user.ownerId, this.contractorListId);
      if (response && response.status === 200) {
        this.data.name = response.data.name;
        this.contractors.items = response.data.contractors;
      }
      this.loading = false;
    },
    confirmDeleteContractorsList() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить список подрядчиков?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteContractorsList();
          }
        });
    },
    async deleteContractorsList() {
      this.loading = true;
      const response = await customersContractorsListDelete(this.$store.state.user.ownerId, this.contractorListId);
      if (response && response.status === 200) {
        this.showSuccess('Список перевозчиков удален');
        this.$router.push({path: '/contractor-lists-list'});
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>
